<template>
  <v-card color="transparent" flat v-if="profile">
    <v-card-title> Güvenlik </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Yeni Şifreniz"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            type="password"
            v-model="$v.myProfile.password.$model"
            :error-messages="passwordErrors()"
          >
            <template #prepend-inner>
              <v-icon small left v-text="'fas fa-key'"> </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Yeni Şifreniz (tekrar)"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            type="password"
            v-model="$v.myProfile.rePassword.$model"
            :error-messages="rePasswordErrors()"
          >
            <template #prepend-inner>
              <v-icon small left v-text="'fas fa-key'"> </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title> Profil </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Adınız Soyadınız"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            v-model="$v.myProfile.name.$model"
            :error-messages="nameErrors()"
          >
            <template #prepend-inner>
              <v-icon small left v-text="'fas fa-user'"> </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <phone-input
            class="phone"
            v-model="$v.myProfile.mobilePhone.$model"
            v-bind:properties="{
              inputmode: 'numeric',
              prefix: '+90',
              dense: true,
              hideDetails: 'auto',
              placeholder: 'Telefon numaranız',
              height: '40',
              flat: true,
              solo: true,
              errorMessages: phoneNumberErrors(),
            }"
            v-bind:options="{
              humanMask: '(###)-###-##-##',
              machineMask: '###########',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          ></phone-input>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="E-posta Adresiniz"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            v-model="$v.myProfile.eMail.$model"
            :error-messages="mailErrors()"
          >
            <template #prepend-inner>
              <v-icon small left v-text="'fas fa-at'"> </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                flat
                dense
                hide-details="auto"
                label="Doğum Tarihiniz"
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="myProfile.birthdate"
              >
                <template #prepend-inner>
                  <v-icon small left v-text="'fas fa-calendar'"> </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              full-width
              :max="$moment().add(-18, 'years').format('YYYY-MM-DD')"
              :min="$moment().add(-80, 'years').format('YYYY-MM-DD')"
              v-model="myProfile.birthdate"
              :active-picker.sync="activePicker"
              @change="changeBirthDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-select
            label="Şehir"
            solo
            item-text="value"
            item-value="id"
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            :items="cities"
            v-model="myProfile.cityId"
          >
            <template #prepend-inner>
              <v-icon small left v-text="'fas fa-location-arrow'"> </v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-radio-group v-model="myProfile.gender" row hide-details="auto" dense>
            <v-radio :label="gender.value" :value="gender.id" v-for="gender in genders" :key="gender.id"> </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn block color="primary" class="text-capitalize" @click="updateProfile" :loading="profileLoading">
          Değişiklikleri kaydet
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_PROFILE_PRESET, UPDATE_PROFILE } from '@/store/modules/account.module';
import { SHOW_MESSAGE } from '@/store/modules/snackBar.module';
import PhoneInput from '@/components/PhoneInput.vue';
import { validationMixin } from 'vuelidate';
import validations from './_validations';
export default {
  name: 'Account',
  mixins: [validationMixin, validations],
  components: {
    PhoneInput,
  },
  data() {
    return {
      menu: false,
      activePicker: null,
      myProfile: {
        name: null,
        mobilePhone: null,
        password: null,
        rePassword: null,
        birthdate: null,
        cityId: null,
        gender: null,
      },
    };
  },
  computed: {
    ...mapGetters(['profileLoading', 'genders', 'cities', 'profile']),
  },
  created() {
    this.$nextTick(() => {
      this.fetchPreset().then(() => {
        this.myProfile = {
          ...this.profile,
          birthdate: this.profile.birthdate !== null ? this.$moment(this.profile.birthdate).format('YYYY-MM-DD') : null,
          rePassword: null,
        };

        if (this.myProfile.mobilePhone.startsWith('90'))
          this.myProfile.mobilePhone = this.myProfile.mobilePhone.replace('90', '');
      });
    });
  },
  methods: {
    ...mapActions({
      showMessage: SHOW_MESSAGE,
      fetchPreset: FETCH_PROFILE_PRESET,
      update: UPDATE_PROFILE,
    }),
    changeBirthDate(date) {
      this.$refs.menu.save(date);
    },
    updateProfile() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const payload = {
        ...this.myProfile,
        birthdate:
          this.myProfile.birthdate !== null ? this.$moment(this.myProfile.birthdate).format('YYYY-MM-DD') : null,
        mobilePhone: `90${this.myProfile.mobilePhone}`,
      };

      delete payload.rePassword;

      this.update(payload).then(() => {
        this.myProfile.password = null;
        this.myProfile.rePassword = null;

        this.showMessage({
          color: 'green',
          message: 'Değişiklikler başarılı bir şekilde kaydedildi!',
        });
      });
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() {
  .phone {
    .v-icon {
      margin-top: 0px !important;
    }
  }
  .v-input {
    &--radio-group__input {
      justify-content: space-around;
    }
    &--selection-controls {
      margin-top: 0px !important;
    }
  }
}
</style>
