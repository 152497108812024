/* eslint-disable no-unused-expressions */
import { required, email, maxLength, helpers, minLength, sameAs } from 'vuelidate/lib/validators';
const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

const validations = {
  validations: {
    myProfile: {
      password: {
        minLength: minLength(6),
        maxLength: maxLength(16),
      },
      rePassword: {
        sameAs: sameAs((o) => o.password),
      },
      name: {
        required,
        maxLength: maxLength(30),
      },
      mobilePhone: {
        required,
        phoneCheck,
      },
      eMail: {
        email,
      },
    },
  },
  methods: {
    passwordErrors() {
      const errors = [];
      const { $dirty, minLength, maxLength, $params } = this.$v.myProfile.password;
      if (!$dirty) return errors;
      !minLength && errors.push(`Şifre minimum ${$params.minLength.min} karakter olmalıdır.`);
      !maxLength && errors.push(`Şifre maksimum ${$params.maxLength.max} karakter olabilir.`);
      return errors;
    },
    rePasswordErrors() {
      const errors = [];
      const { $dirty, sameAs } = this.$v.myProfile.rePassword;
      if (!$dirty) return errors;
      !sameAs && errors.push(`Şifreler birbirinden farklı`);
      return errors;
    },
    nameErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.myProfile.name;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen adınızı soyadınızı giriniz.');
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      const { $dirty, required, phoneCheck } = this.$v.myProfile.mobilePhone;
      if (!$dirty) return errors;
      !required && errors.push('Bu bilgi gereklidir.');
      !phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
      return errors;
    },
    mailErrors() {
      const errors = [];
      const { $dirty, email } = this.$v.myProfile.eMail;
      if (!$dirty) return errors;
      !email && errors.push('Lütfen geçerli bir e-posta adresinizi giriniz.');
      return errors;
    },
  },
};

export default validations;
